import React from 'react';
import {Container} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";

export default (props) =>
{
    const styles =
              {
                  paddingTop:      50,
                  paddingBottom:   50,
                  textAlign:       "center",
                  backgroundColor: "#f7f7f7",
                  borderTop: "1px solid #eee",
                  borderBottom: "1px solid #eee"
              };
    if(props.first)
    {
        styles.borderTopRightRadius = 6;
        styles.borderTopLeftRadius  = 6;
    }
    if(props.last)
    {
        styles.borderBottomRightRadius = 6;
        styles.borderBottomLeftRadius  = 6;
    }
    return <>
        <div style={styles}>
            <Container maxWidth="lg">
                <Grid
                    container
                    direction="row"
                    justify="space-evenly"
                    alignItems="stretch"
                    spacing={7}
                >
                    <Grid item sm={6} xs={12}>
                        <img src={props.img} alt="Zum Productions" style={{
                            height:    200
                        }}/>
                    </Grid>
                    <Grid item sm={6} xs={12} style={{textAlign:"justify"}}>
                        <Typography variant="h4" component="h3">{props.h}</Typography>
                        <p>{props.txt}</p>
                    </Grid>
                </Grid>
            </Container>
        </div>
    </>;
};
