import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export default (props) =>
{
    const styles =
              {
                  paddingTop:      50,
                  paddingBottom:   50,
              };
    if(props.first)
    {
        styles.borderTopRightRadius = 6;
        styles.borderTopLeftRadius  = 6;
    }
    if(props.last)
    {
        styles.borderBottomRightRadius = 6;
        styles.borderBottomLeftRadius  = 6;
    }
    
    const tiers =
    [
        {
            title: 'Free',
            price: '0',
            description: ['10 users included', '2 GB of storage', 'Help center access', 'Email support'],
            buttonText: 'Sign up for free',
            buttonVariant: 'outlined',
        },
        {
            title: 'Pro',
            subheader: 'Most popular',
            price: '15',
            description: [
                '20 users included',
                '10 GB of storage',
                'Help center access',
                'Priority email support',
            ],
            buttonText: 'Get started',
            buttonVariant: 'contained',
        },
        {
            title: 'Enterprise',
            price: '30',
            description: [
                '50 users included',
                '30 GB of storage',
                'Help center access',
                'Phone & email support',
            ],
            buttonText: 'Contact us',
            buttonVariant: 'outlined',
        },
    ];
    
    return (
        <Container maxWidth="md" component="main" style={styles}>
            <Grid container spacing={5} alignItems="flex-end">
                {tiers.map(tier => (
                    // Enterprise card is full width at sm breakpoint
                    <Grid item key={tier.title} xs={12} sm={tier.title === 'Enterprise' ? 12 : 6} md={4}>
                        <Card>
                            <CardHeader
                                title={tier.title}
                                subheader={tier.subheader}
                                titleTypographyProps={{ align: 'center' }}
                                subheaderTypographyProps={{ align: 'center' }}
                                action={tier.title === 'Pro' ? "star" : null}
                            />
                            <CardContent>
                                <div>
                                    <Typography component="h2" variant="h3" color="textPrimary">
                                        ${tier.price}
                                    </Typography>
                                    <Typography variant="h6" color="textSecondary">
                                        /mo
                                    </Typography>
                                </div>
                                <ul>
                                    {tier.description.map(line => (
                                        <Typography component="li" variant="subtitle1" align="left" key={line}>
                                            {line}
                                        </Typography>
                                    ))}
                                </ul>
                            </CardContent>
                            <CardActions>
                                <Button fullWidth variant={tier.buttonVariant} color="primary">
                                    {tier.buttonText}
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
}
